import React, {FC} from 'react';
import {IProduct} from '../../../../../types/galleryTypes';
import {ColorPicker} from 'wix-ui-tpa/cssVars';
import {RadioButtonChangeEvent, RadioButtonClickEvent} from 'wix-ui-core';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import s from '../ProductOptions.scss';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import {classes as colorOptionStyles} from './ColorOption.st.css';
import classNames from 'classnames';
import {withGlobals} from '../../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../../gallery/galleryGlobalStrategy';
import {Experiments} from '../../../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ConditionalRender} from '../../../ConditionalRender/ConditionalRender';
import {VIEW_MORE_OPTIONS_URL_FRAGMENT} from '@wix/wixstores-client-storefront-sdk/dist/src/constants';
import {ProductOptionsChangeData, DataHook, OptionRenderParams} from '../ProductOptions';

interface IProductColorOptionProps extends IGalleryGlobalProps {
  selectionIds: number[];
  onSelectionIdsChange(
    optionSelections: IProductColorOptionProps['selectionIds'],
    changeData: ProductOptionsChangeData
  ): Promise<void>;
  option: IProduct['options'][number];
  availability: IProductSelectionAvailabilityMap[string];
  url: string;
}

const ColorOption: FC<IProductColorOptionProps & IProvidedTranslationProps> = (props) => {
  const {
    availability,
    t,
    url,
    selectionIds,
    onSelectionIdsChange,
    option,
    globals: {useExperiments, isRTL, stylesParams, styles, isLiveSiteMode, isPreviewMode},
  } = props;
  const colorOptionsDefaultSizeViewer = useExperiments.enabled(Experiments.ColorOptionsDefaultSizeViewer);
  const productOptionsLimitEnabled = useExperiments.enabled(Experiments.GalleryProductOptionsLimit);

  const mapSelectionToColorPickerItem = (selection, additionalClass: string) => {
    const isDisabled = !availability[selection.id].isSelectable;
    return (
      <ColorPicker.Item
        key={selection.id}
        className={classNames(additionalClass)}
        aria-label={selection.description}
        value={selection.value}
        disabled={isDisabled}
        isCrossedOut={isDisabled}
        checked={selectionIds.includes(selection.id)}
      />
    );
  };

  const getColorPickerItems = () => {
    const visibleSelections = option.selections.filter((selection) => availability[selection.id].isVisible);
    const generateColorPickerItems = (selections, additionalClass = '') =>
      selections.map((selection) => mapSelectionToColorPickerItem(selection, additionalClass));

    if (!productOptionsLimitEnabled) {
      return generateColorPickerItems(visibleSelections);
    }

    const productOptionSwatchesLimit = styles.get(stylesParams.gallery_productOptionSwatchesLimit);
    const shownSelections = visibleSelections.slice(0, productOptionSwatchesLimit);
    const remainingSelections = visibleSelections.slice(productOptionSwatchesLimit);

    return [
      ...generateColorPickerItems(shownSelections),
      ...generateColorPickerItems(remainingSelections, s.colorPickerItemRemaining),
    ];
  };

  const getRemainingColorsCounter = () => {
    if (!productOptionsLimitEnabled) {
      return 0;
    }
    const productOptionSwatchesLimit = styles.get(stylesParams.gallery_productOptionSwatchesLimit);
    const visibleSelections = option.selections.filter((selection) => availability[selection.id].isVisible);
    return visibleSelections.length - productOptionSwatchesLimit;
  };

  const getRemainingColorsCounterSuffix = () => {
    const remainingColorsCounter = getRemainingColorsCounter();
    const showRemainingColorsCounterSuffix = productOptionsLimitEnabled && remainingColorsCounter > 0;
    if (!showRemainingColorsCounterSuffix) {
      return null;
    }

    const FIRST_COLOR_PICKER_URL_FRAGMENT = `${VIEW_MORE_OPTIONS_URL_FRAGMENT}-1`;
    const urlObj = new URL(url);
    urlObj.hash = FIRST_COLOR_PICKER_URL_FRAGMENT;
    return (
      <ConditionalRender by={'showColorPickerLimit'}>
        <a
          dir="ltr"
          href={isLiveSiteMode || isPreviewMode ? urlObj.href : null}
          data-hook={DataHook.ColorOptionMore}
          className={s.remainingColorsCounter}
          aria-label={t('colorSwatchNumMoreOptionsAriaLabel', {numRemainingColors: remainingColorsCounter})}>
          {t('colorSwatchNumChoicesLeftLabel', {numChoiceLeft: remainingColorsCounter})}
        </a>
      </ConditionalRender>
    );
  };

  const sealColorChangeHandler =
    (optionsSelections: OptionRenderParams['option']['selections']) =>
    (event: RadioButtonChangeEvent | RadioButtonClickEvent) => {
      const pickedSelectionId = optionsSelections.find(({value}) => value === event.value).id;

      const nextSelectionIds = [
        ...selectionIds.filter((selectionId) => !optionsSelections.find(({id}) => id === selectionId)),
        pickedSelectionId,
      ];

      void onSelectionIdsChange(nextSelectionIds, {
        optionType: ProductOptionType.COLOR,
      });
    };

  return (
    <div key={option.id} data-hook={DataHook.ProductOption} dir={isRTL ? 'rtl' : 'ltr'}>
      <ColorPicker
        className={classNames({
          [colorOptionStyles.colorOption]: colorOptionsDefaultSizeViewer,
          [colorOptionStyles.colorOptionOldDefault]: !colorOptionsDefaultSizeViewer,
        })}
        onChange={sealColorChangeHandler(option.selections)}
        data-hook={DataHook.ColorOption}
        aria-label={option.title}
        suffix={getRemainingColorsCounterSuffix()}>
        {getColorPickerItems()}
      </ColorPicker>
    </div>
  );
};

export const ProductColorOption = withGlobals(withTranslations()(ColorOption));
